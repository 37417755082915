import { getPlatformFeatures } from '@finn/platform-modules';
import type { Filters } from '@finn/ua-vehicle';
import { CampaignCard, CampaignCardProps } from '@finn/ua-vehicle';
import type {
  B2BContactFormCosmicMetadata,
  BlurbCosmicMetadata,
  ContactUsFormCosmicMetadata,
  ContentSliderCosmicData,
  EmailCollectionFormMetadata,
  FeaturedVehicleCosmicMetadata,
  FormCosmicData,
  JobAutoFormCosmicMetadata,
  LoyaltyCosmicMetadata,
  PartnershipsFormCosmicMetadata,
  PromoBannerCosmicMetadata,
  SustainabilityCosmicData,
  VerticalListMetadata,
  VoucherCodeFormCosmicMetadata,
} from '@finn/ui-cosmic';
import { ChoicePairData, cosmicFactory } from '@finn/ui-cosmic';
import { NumericHeadingSizes } from '@finn/ui-modules';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';

import PageDataContext from '~/contexts/PageData';
import type { ContentExperimentMetadata } from '~/modules/cosmic/modules/ContentExperiment/ContentExperimentMetadata';
import type { ProductGridMetadata } from '~/modules/cosmic/modules/ProductGrid/ProductGridMetadata';
import type { TableOfContentsMetadata } from '~/modules/cosmic/modules/TableOfContents';
import type { TestimonialCosmicMetadata } from '~/modules/cosmic/modules/Testimonial/Testimonial.types';
import type { StickyCTAPanelConfig } from '~/modules/landingPages/components/StickyCTAPanel';
import type {
  B2BLeadGenFormCosmicMetadata,
  BrandSliderCosmicData,
  ComparisonCosmicMetadata,
  ContentCosmicData,
  CustomBrandSliderCosmicData,
  FlipperCosmicMetadata,
  GalleryCosmicMetadata,
  HeroCosmicMetadata,
  HTMLContentData,
  NPSSliderCosmicData,
  PromoCosmicMetadata,
  StepperCosmicMetadata,
  StepsCounterCosmicMetadata,
  TextblockCosmicMetadata,
  VerticalTabCosmicMetadata,
} from '~/types/UIModules';
import { CosmicModuleType, FlipperSlug, FormSlug } from '~/types/UIModules';
import { CancelationFormCosmicMetadata } from '~/ui-modules/CancelationForm/utils/types';
import type { Hero2CosmicMetadata } from '~/ui-modules/Hero2/metaData';
import { ProductSliderCosmicMetadata } from '~/ui-modules/ProductSlider/types';
import type { StickyNavMetadata } from '~/ui-modules/StickyNav/types';
import type { VideoCosmicMetadata } from '~/ui-modules/Video/meta';

const ContentExperiment = dynamic(
  () => import('~/modules/cosmic/modules/ContentExperiment')
);

const B2BContactForm = dynamic(
  () => import('~/ui-modules/ContactForm/B2BContactForm')
);

const Testimonial = dynamic(
  () => import('~/modules/cosmic/modules/Testimonial/Testimonial')
);

const B2BLeadGenForm = dynamic(
  () => import('../ui-modules/ContactForm/B2BLeadGenForm')
);

const Hero = dynamic(() => import('~/ui-modules/Hero'));
const Hero2 = dynamic(() => import('~/ui-modules/Hero2'));
const Promo = dynamic(() => import('~/ui-modules/Promo'));
const DynamicPromo = dynamic(() => import('~/ui-modules/DynamicPromo'));
const CustomModules = dynamic(() => import('~/ui-modules/CustomModules'));
const Stepper = dynamic(() => import('~/ui-modules/Stepper'));
const Textblock = dynamic(() => import('~/ui-modules/Textblock'));
const NPSSlider = dynamic(() => import('~/ui-modules/NPSSlider'));
const BrandSlider = dynamic(() => import('~/ui-modules/BrandSlider'));
const CustomBrandSlider = dynamic(
  () => import('~/ui-modules/CustomBrandSlider')
);
const ContentSlider = dynamic(() => import('~/ui-modules/ContentSlider'));
const ProductSlider = dynamic(() => import('~/ui-modules/ProductSlider'));
const VerticalTab = dynamic(() => import('~/ui-modules/VerticalTab'));
const Flipper = dynamic(() =>
  import('@finn/platform-modules').then((res) => res.Flipper)
);
const ReferralFlipper = dynamic(
  () => import('~/components/ReferralFlipper/ReferralFlipper')
);

const ComparisonSection = dynamic(
  () => import('~/ui-modules/ComparisonSection')
);
const ContactUsForm = dynamic(
  () => import('~/ui-modules/ContactForm/ContactUsForm')
);
const JobAutoForm = dynamic(
  () => import('~/ui-modules/ContactForm/JobAutoContactForm')
);
const PartnershipsForm = dynamic(
  () => import('~/ui-modules/ContactForm/PartnershipsContactForm')
);

const CO2EmissionCalculator = dynamic(
  async () => await import('~/components/Sustainability/CO2EmissionCalculator')
);
const WebModuleChoicePair = dynamic(
  async () =>
    await import('~/components/WebModuleChoicePair/WebModuleChoicePair')
);
const RetentionVoucherCodeContactForm = dynamic(
  () =>
    import(
      '~/ui-modules/ContactForm/VoucherCodeContactForm/RetentionVoucherCodeContactForm'
    )
);
const VerticalList = dynamic(() => import('~/ui-modules/VerticalList'));
const StickyNav = dynamic(() => import('~/ui-modules/StickyNav'));
const StepsCounter = dynamic(() => import('~/ui-modules/StepsCounter'));
const EmailCollectionForm = dynamic(
  () => import('~/ui-modules/EmailCollectionForm/EmailCollectionForm')
);
const Gallery = dynamic(() => import('../ui-modules/Gallery'));
const LoyaltyLevels = dynamic(() =>
  import('@finn/ui-modules').then((m) => m.LoyaltyLevels)
);
const HowToCollectPointsStandaloneModule = dynamic(() =>
  import('@finn/ui-modules').then((m) => m.HowToCollectPointsStandaloneModule)
);
const Video = dynamic(() => import('../ui-modules/Video'));
const FeaturedVehicle = dynamic(() => import('../ui-modules/FeaturedVehicle'));
const HTMLContent = dynamic(() => import('../ui-modules/HTMLContent'));
const Blurb = dynamic(() => import('~/ui-modules/Blurb'));
const CancelationForm = dynamic(() => import('../ui-modules/CancelationForm'));
const CancelationConfirmation = dynamic(
  () => import('../ui-modules/CancelationConfirmation')
);
const ProductList = dynamic(() => import('../ui-modules/ProductList'));
const PromoBanner = dynamic(() => import('../ui-modules/PromoBanner'));

const ProductGrid = dynamic(
  () => import('~/modules/cosmic/modules/ProductGrid/ProductGrid')
);

const TableOfContents = dynamic(
  () => import('~/modules/cosmic/modules/TableOfContents/TableOfContents')
);

const StickyCTAPanel = dynamic(
  () => import('~/modules/landingPages/components/StickyCTAPanel')
);

const cosmicModules = {
  ...getPlatformFeatures(),
  [CosmicModuleType.WEB_MODULE_GALLERY]: function GalleryModule({ data }) {
    return <Gallery data={data.metadata as GalleryCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_HERO]: function HeroModule({ data }) {
    return <Hero data={data.metadata as HeroCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_HERO_2]: function HeroModule({ data }) {
    return (
      <Hero2 data={data.metadata as Hero2CosmicMetadata} slug={data.slug} />
    );
  },
  [CosmicModuleType.WEB_MODULE_CAMPAIGN_CARD]: function CampaignCardModule({
    data,
  }) {
    return <CampaignCard campaignData={data as CampaignCardProps} />;
  },
  [CosmicModuleType.WEB_MODULE_BLURB]: function BlurbModule({ data }) {
    return <Blurb data={data.metadata as unknown as BlurbCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_FLIPPER]: function FlipperModule({
    data: { metadata, slug },
  }) {
    return slug === FlipperSlug.FINN_FOR_FRIENDS ? (
      <ReferralFlipper data={metadata as FlipperCosmicMetadata} />
    ) : (
      <Flipper slug={slug} data={metadata as FlipperCosmicMetadata} />
    );
  },
  [CosmicModuleType.WEB_MODULE_TEXT]: function TextblockModule({ data }) {
    return <Textblock data={data.metadata as TextblockCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_PRODUCT_GRID]: function ProductGridModule({
    data,
  }) {
    return (
      <ProductGrid data={data.metadata as unknown as ProductGridMetadata} />
    );
  },
  [CosmicModuleType.WEB_MODULE_SLIDER]: function ContentSliderModule({ data }) {
    return (
      <ContentSlider
        title={(data as ContentSliderCosmicData).metadata.title}
        contents={(data as ContentCosmicData).metadata.items}
      />
    );
  },
  [CosmicModuleType.PRODUCT_SLIDER]: function ProductSliderModule({ data }) {
    const metadata = data.metadata as ProductSliderCosmicMetadata;
    const filter: Filters = {
      ...metadata.filters,
      min_price: metadata.filters.min_price || undefined,
      max_price: metadata.filters.min_price || undefined,
      sort: metadata.sort.value,
    };

    if (metadata.layout?.key === 'list') {
      return <ProductList data={metadata} />;
    }

    return (
      <ProductSlider
        title={metadata.title}
        headingStyle={
          metadata?.heading_style?.key
            ? (parseInt(metadata.heading_style.key, 10) as NumericHeadingSizes)
            : undefined
        }
        filter={filter}
        hidePrice={metadata.hide_price}
        forceB2BPrice={metadata.always_show_b2b_price}
        cta={metadata.cta}
        vehicles={metadata.vehicles}
      />
    );
  },
  [CosmicModuleType.WEB_MODULE_STEPPER]: function StepperModule({ data }) {
    return <Stepper data={data.metadata as StepperCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_STEP_COUNTER]: function StepsCounterModule({
    data,
  }) {
    return <StepsCounter data={data.metadata as StepsCounterCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_CAR_PROMO]: function FeaturedVehicleModule({
    data,
  }) {
    return (
      <FeaturedVehicle data={data.metadata as FeaturedVehicleCosmicMetadata} />
    );
  },
  [CosmicModuleType.WEB_MODULE_VERTICAL_TABS]: function VerticalTabModule({
    data,
  }) {
    return <VerticalTab data={data.metadata as VerticalTabCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_PROMO]: function PromoModule({ data }) {
    return (
      <Promo data={data.metadata as PromoCosmicMetadata} slug={data.slug} />
    );
  },
  [CosmicModuleType.WEB_MODULE_DYNAMIC_PROMO]: function DynamicPromoModule({
    data,
  }) {
    return (
      <DynamicPromo
        data={data.metadata as PromoCosmicMetadata}
        slug={data.slug}
      />
    );
  },
  [CosmicModuleType.WEB_MODULE_CUSTOM_PLACEHOLDER]: function CustomModulesFunc({
    data,
  }) {
    return (
      <CustomModules
        data={data.metadata as PromoCosmicMetadata}
        slug={data.slug}
      />
    );
  },
  [CosmicModuleType.WEB_MODULE_TRUSTPILOT]: function NPSSliderModule({ data }) {
    const pageData = useContext(PageDataContext);

    return (
      <NPSSlider
        title={(data as NPSSliderCosmicData).metadata.title}
        reviews={pageData.reviews}
      />
    );
  },
  [CosmicModuleType.WEB_MODULE_BRAND_SLIDER]: function BrandSliderModule({
    data,
  }) {
    const pageData = useContext(PageDataContext);

    return (
      <BrandSlider
        title={(data as BrandSliderCosmicData).metadata.title}
        brands={pageData.brands}
      />
    );
  },
  [CosmicModuleType.WEB_MODULE_CUSTOM_BRAND_SLIDER]:
    function BrandSliderModule({ data }) {
      const customBrandSliderData = data as CustomBrandSliderCosmicData;
      const { title, items } = customBrandSliderData.metadata || {};

      return <CustomBrandSlider title={title} items={items} />;
    },
  [CosmicModuleType.WEB_MODULE_HTMLS]: function HTMLContentModule({ data }) {
    return (
      <HTMLContent
        content={(data as HTMLContentData).metadata.html_content}
        centered={(data as HTMLContentData).metadata.centered}
      />
    );
  },
  [CosmicModuleType.WEB_MODULE_FORMS]: function FormModule({
    data,
  }: {
    data: FormCosmicData;
  }) {
    const moduleSlug = data.metadata?.form_module?.key || data.slug;
    switch (moduleSlug) {
      case FormSlug.B2B: {
        return (
          <B2BContactForm
            data={data.metadata as B2BContactFormCosmicMetadata}
          />
        );
      }
      case FormSlug.CONTACT_US: {
        return (
          <ContactUsForm
            data={data.metadata as unknown as ContactUsFormCosmicMetadata}
          />
        );
      }
      case FormSlug.RETENTION_VOUCHER: {
        return (
          <RetentionVoucherCodeContactForm
            data={data.metadata as VoucherCodeFormCosmicMetadata}
          />
        );
      }
      case FormSlug.JOBAUTO: {
        return (
          <JobAutoForm data={data.metadata as JobAutoFormCosmicMetadata} />
        );
      }
      case FormSlug.PARTNERSHIPS: {
        return (
          <PartnershipsForm
            data={data.metadata as PartnershipsFormCosmicMetadata}
          />
        );
      }
      case FormSlug.CANCELATION: {
        return (
          <CancelationForm
            cosmicData={data.metadata as CancelationFormCosmicMetadata}
          />
        );
      }
      case FormSlug.CANCELATION_CONFIRMATION: {
        return (
          <CancelationConfirmation
            cosmicData={data.metadata as CancelationFormCosmicMetadata}
          />
        );
      }
      case FormSlug.B2B_LEAD_GENERATION_FORM: {
        return (
          <B2BLeadGenForm
            data={data.metadata as B2BLeadGenFormCosmicMetadata}
          />
        );
      }
      default: {
        console.error(
          `No matching Form for type: ${data.metadata?.form_module?.key} or slug: ${data.slug}`
        );

        return null;
      }
    }
  },
  [CosmicModuleType.WEB_MODULE_SUSTAINABILITY]:
    function CO2EmissionCalculatorModule({ data }) {
      return <CO2EmissionCalculator data={data as SustainabilityCosmicData} />;
    },
  [CosmicModuleType.WEB_MODULE_CHOICE_PAIR]:
    function WebModuleChoicePairModule({ data }) {
      return <WebModuleChoicePair data={data as ChoicePairData} />;
    },
  [CosmicModuleType.WEB_MODULE_COMPARISON]: function ComparisonSectionModule({
    data,
  }) {
    return (
      <ComparisonSection data={data.metadata as ComparisonCosmicMetadata} />
    );
  },
  [CosmicModuleType.WEB_MODULE_VIDEOS]: function VideoModule({ data }) {
    return <Video data={data.metadata as unknown as VideoCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_VERTICAL_LIST]: function VerticalListModule({
    data,
  }) {
    return <VerticalList data={data.metadata as VerticalListMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_EMAIL_COLLECTION_FORMS]:
    function EmailCollectionFormModule({ data }) {
      return (
        <EmailCollectionForm
          data={data.metadata as EmailCollectionFormMetadata}
        />
      );
    },
  [CosmicModuleType.WEB_MODULE_HERO_STICKY_NAVS]: function StickNavModule({
    data,
    stickyNavContent,
  }) {
    return !stickyNavContent ? null : (
      <StickyNav
        data={data.metadata as StickyNavMetadata}
        stickyNavContent={stickyNavContent}
      />
    );
  },
  [CosmicModuleType.WEB_MODULE_TESTIMONIALS]: function TestimonialsModule({
    data,
  }) {
    return <Testimonial data={data.metadata as TestimonialCosmicMetadata} />;
  },
  [CosmicModuleType.WEB_MODULE_PROMO_BANNERS]: function PromoBannersModule({
    data,
  }) {
    return (
      <PromoBanner pageData={data.metadata as PromoBannerCosmicMetadata} />
    );
  },
  [CosmicModuleType.WEB_MODULE_CONTENT_EXPERIMENT]: ({ data }) => (
    <ContentExperiment
      data={data.metadata as unknown as ContentExperimentMetadata}
    />
  ),
  [CosmicModuleType.WEB_MODULE_TABLE_OF_CONTENTS]: ({ data }) => (
    <TableOfContents
      data={data.metadata as unknown as TableOfContentsMetadata}
    />
  ),
  [CosmicModuleType.WEB_MODULE_STICKY_CTA_PANEL]: ({ data }) => (
    <StickyCTAPanel data={data.metadata as StickyCTAPanelConfig} />
  ),
  [CosmicModuleType.WEB_MODULE_LOYALTY]: function LoyaltyModule({ data }) {
    return (
      <LoyaltyLevels data={data.metadata as unknown as LoyaltyCosmicMetadata} />
    );
  },
  [CosmicModuleType.WEB_MODULE_LOYALTY_EARNING_POINTS]:
    function HowToCollectPointsModule({ data }) {
      return (
        <HowToCollectPointsStandaloneModule
          data={data.metadata as unknown as LoyaltyCosmicMetadata}
        />
      );
    },
};

export const factory = cosmicFactory({ ...cosmicModules });
